.pswp {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  inset: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  width: 100%;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  width: 100%;
  text-align: center;
  color: #ccc;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

/*# sourceMappingURL=index.77d8e953.css.map */
